<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Business Plans')"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      v-show="this.canWrite()"
      >{{ __("Create Business Plan") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="businessPlanTable"
            v-loading="isLoading"
            :data="businessPlans"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            highlight-current-row
            class="list-table"
            v-show="this.canRead()"
          >
            <el-table-column :label="__('Plan Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.bp_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Plan Type')">
              <template slot-scope="scope">
                <span>{{ scope.row.plan_type }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Unit Price')">
              <template slot-scope="scope">
                <span>{{ scope.row.price_per_unit }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";

export default {
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: "",
      filters: ["bp_id"],
      sortByColumns: ["bp_name", "bp_id"]
    };
  },

  computed: {
    ...mapState("businessplans", {
      businessPlans: state => state.businessPlans,
      isLoading: state => state.isLoading
    })
  },

  methods: {
    ...mapActions("businessplans", {
      contentAPI: "getBusinessPlans",
      deleteContentMethod: "deleteBusinessPlan",
      undoDeleteContent: "undoDeleteBusinessPlan"
    }),

    handleClear() {
      this.$refs.businessPlanTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    }
  },

  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.businessPlanTable.setCurrentRow(this.businessPlans[val]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
