import { render, staticRenderFns } from "./listBusinessPlans.vue?vue&type=template&id=08505198&scoped=true"
import script from "./listBusinessPlans.vue?vue&type=script&lang=js"
export * from "./listBusinessPlans.vue?vue&type=script&lang=js"
import style0 from "./listBusinessPlans.vue?vue&type=style&index=0&id=08505198&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08505198",
  null
  
)

export default component.exports